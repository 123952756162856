// Fade's in element when the element is scrolled into view
const elementFadeIn = () => {
  const options = {
    threshold: 0.8
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('in');
      }
    })
  }, options);

  const targets = document.querySelectorAll('.el-fade-in');
  targets.forEach(target => {
    observer.observe(target)
  })
};

elementFadeIn();