import LazyLoad from "vanilla-lazyload";
import Rellax from "rellax";
import "owl.carousel";

let lazyLoad = new LazyLoad();

let rellax = new Rellax('.rellax', {
    center: true,
    vertical: true,
    horizontal: false,
    breakpoints: [576, 768, 1201]
});
