/**
 * Each instance of this class represent a Modal containing a Wistia video embed
 * that allows us to unmute and auto play video when modal loads up
 * and remove the video when modal is closed
 * @param {String} targetId
 */

const WistiaModal = class {
  targetId = null
  modalEl = null
  wistiaEl = null
  modalBackdrop = null

  constructor(targetId) {
    this.targetId = targetId
    this.modalEl = document.getElementById(targetId)
    this.wistiaEl = this.modalEl.querySelector('.wistia_embed')
    this.modalBackdrop = document.querySelector('.modal-backdrop')
    this.closeBtnEl = this.modalEl.querySelector('button#btn-close')
    this.dismissModal()
  }

  show() {
    this.modalEl.classList.add('show')
    this.playVideo()
    this.unmuteVideo()
  }

  hide() {
    this.modalEl.classList.remove('show')
    this.removeVideo()
  }

  wistiaVideo() {
    return Wistia.api(this.wistiaEl.id)
  }

  playVideo() {
    this.wistiaVideo().play()
  }

  unmuteVideo() {
    setTimeout(() => {
      this.wistiaVideo().unmute()
    }, 200)
  }

  dismissModal() {
    this.closeBtnEl.addEventListener('click', () => {
      this.hide()
      this.modalBackdrop.remove()
    })
  }

  removeVideo() {
    this.wistiaVideo().pause()
  }
}

export default WistiaModal