/**
 * Copyright (C) 2022. ProMobi Technologies Pvt. Ltd. All Rights Reserved. 
 * Author: Arnab Chakraborty (arnab@promobitech.com)
 */

// provides default share functionality of the OS, @see WebShare API

const enableShareFeatures = () => {

    // Our idea is to use WebShare APIs so we check 
    // whether the browser supprorts it in the current OS
    // platform, if it does then we'll invoke it
    if (!navigator.canShare) {
        console.warn('navigator.share() not supported, quitting!')
        return 
    }

    // find all share buttons
    let elems = document.querySelectorAll('.share')
    Array.from(elems).forEach((el) => {
        let data = {
            url: el.dataset.url,
            text: `${el.dataset.title} | ${el.dataset.text}`
        }

        // if the browser can't share this data then no point going forward
        if (!navigator.canShare(data)) return

        // we can show the button now if it is hidden
        if (el.classList.contains('hidden')) {
            el.classList.remove('hidden')
        }

        el.addEventListener('click', async (e) => {
            e.preventDefault()

            try {
                await navigator.share(data)
            } catch (err) {
                console.error(err)
                // alert('Sharing failed - ' + err.message)
            }
        })
    })
}


// invoke when dom is ready
$(enableShareFeatures)