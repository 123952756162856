
const navbarToggle = () => {
    let toggleBtn = document.querySelector("#btn-navbar-toggle");

    toggleBtn.addEventListener("click", () => {
        toggleBtn.classList.toggle("open");
    })
}

const toggleNavbarBgColor = () => {
    let navbar = document.querySelector("nav.navbar");
    $(window).on("load scroll", () => {
        if (window.pageYOffset > 0) {
            navbar.classList.add("bg-white")
        }
        else {
            navbar.classList.remove("bg-white")
        }
    })
}

$(navbarToggle)
$(toggleNavbarBgColor)