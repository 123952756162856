/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('./images', true)
const imagePath = (name) => images(name, true)

window.$ = require('jquery');

import './src/plugins/index';
import './src/common/navbar';

import './src/common/share';
import WistiaModal from './src/common/wistiaModal';

// We only load our job form JS on the pages the form is present
if (document.querySelectorAll("#job-form").length) {
    import("./src/pages/JobForm") // webpack will load this JS async
}

// We only load our home.js for index page
if (document.querySelectorAll(".promobi-home").length) {
    import("./src/pages/home") // webpack will load this JS async
}

// We only load our hero slider js for required page
if (document.querySelectorAll(".promobi-about, .women-in-promobi").length) {
    import("./src/common/hero-slider") // webpack will load this JS async
}

// load job filtering in /jobs and /careers pages
if (document.querySelector('#open-positions')) {
    import("./src/pages/filter")
}

// load employee benefits carousel in promobi culture page
if (document.querySelector('#employee-benefits-carousel')) {
    import('./src/pages/promobiCulture');
}
if (document.querySelector('.workspace-carousel')) {
    import('./src/common/workspace_slider')
}

import './src/common/element_fade_in';

if (document.querySelector('.ten-years-in-business')) {
    import('./src/common/fireworks_video')
}

$(() => {
    const modalBtns = document.querySelectorAll('button[data-toggle="modal"]');
    if (!modalBtns) return

    Array.from(modalBtns, btn => {
        btn.addEventListener('click', () => {
            const target = btn.dataset.target;
            const modalBackdropEl = document.createElement('div')

            modalBackdropEl.classList.add('modal-backdrop')
            modalBackdropEl.classList.add('show')
            document.body.appendChild(modalBackdropEl)

            new WistiaModal(target).show()
        })
    })
})
